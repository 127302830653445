.eb-btn-blue {
  border: 1px solid #72b9b9;
  border-radius: 16px;
  text-align: center;
  background-color: #fff;
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 1px;
  color: #72b9b9;
  cursor: pointer;
  width: 200px;
  height: 45px;
}

.eb-btn-blue:focus {
  outline: 0 !important;
}

.eb-btn-blue:hover {
  background-color: #ecf8f8;
}

.eb-text-blue {
  color: #72b9b9;
}

.eb-center {
  color: #444;
  text-align: center;
  width: 450px;
  height: 470px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  line-height: 23px;
}
