/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local(''),
    url('./assets/fonts/roboto-v29-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./assets/fonts/roboto-v29-latin-300.woff')
      format('woff'),
    /* Modern Browsers */ url('./assets/fonts/roboto-v29-latin-300.ttf')
      format('truetype'); /* Safari, Android, iOS */
  font-display: swap;
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('./assets/fonts/roboto-v29-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/roboto-v29-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/roboto-v29-latin-regular.ttf')
      format('truetype'); /* Safari, Android, iOS */
  font-display: swap;
}

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local(''),
    url('./assets/fonts/roboto-v29-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./assets/fonts/roboto-v29-latin-500.woff')
      format('woff'),
    /* Modern Browsers */ url('./assets/fonts/roboto-v29-latin-500.ttf')
      format('truetype'); /* Safari, Android, iOS */
  font-display: swap;
}

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local(''),
    url('./assets/fonts/roboto-v29-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./assets/fonts/roboto-v29-latin-700.woff')
      format('woff'),
    /* Modern Browsers */ url('./assets/fonts/roboto-v29-latin-700.ttf')
      format('truetype'); /* Safari, Android, iOS */
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
