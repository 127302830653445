.scroll-top {
  width: 40px;
  height: 45px;
  right: 10px;
  bottom: 5px;
  opacity: 0.4;
  background: #333;
  cursor: pointer;
  position: fixed;
  z-index: 2000;
  padding: 10px;
  animation: fade-in 0.3s;
}

.hide-btn {
  background-color: #333;
  color: white;
  text-align: center;
  opacity: 0.6;
  line-height: 15px;
  width: 17px;
  height: 17px;
  right: 7px;
  bottom: 47px;
  cursor: pointer;
  position: fixed;
  z-index: 2000;
  border-radius: 50%;
}

.hide-btn:hover {
  opacity: 1;
}

.scroll-top:hover {
  opacity: 1;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
}
